$bg: #333;
$push-color: hsla(10, 90%, 40%, 1);

$push-size: 180px;

.slot-machine {
  display: flex;
  width: 500px;
  flex-direction: column;
  align-items: center;
  position: relative;

  .message {
    text-shadow: 0 0 1px #ffee67, 0 0 13px #ffed63;
    font-size: 26px;
    height: 30px;
    color: #fff;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
  }

  .jackpot {
    text-shadow: 0 0 1px #ffee67, 0 0 13px #ffed63;
    font-size: 26px;
    height: 30px;
    color: #fff;
    font-weight: bold;
    margin-top: 10px;
    text-align: center;
    position: absolute;
    top: 72px;
    margin-left: -15px;
  }

  .utils {
    display: flex;
    justify-content: space-evenly;
    flex: 1 1;
    width: 284px;
    /* margin-top: 16px; */
    flex-direction: column;
    /* background-color: #333333; */
    top: 490px;
    position: absolute;
    margin-left: -10px;
  }

  .collect-container {
    position: absolute;
    top: 408px;
    // background-color: #44444466;
    width: 282px;
    display: flex;
    height: 39px;
    margin-left: -13px;
  }

  .winning {
    height: 100%;
    font-weight: bold;
    width: 150px;
    text-align: center;
    line-height: 40px;
  }

  .collect-button {
    font-weight: bold;
    width: 150px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    cursor: pointer;
    &:hover {
      text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0fa,
        0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa, 0 0 151px #0fa;
    }
  }

  .screen-container {
    background-size: contain;
    background-repeat: no-repeat;
    width: 277px;
    height: 200px;
    position: absolute;
    z-index: -1;
    margin-top: 168px;
    margin-left: -21px;
  }

  .lever-position {
    position: absolute;
    right: 0;
    top: 134px;
  }

  .buttons-container {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button.spin {
    }

    .results-container {
      padding-top: 40px;
      display: flex;
      cursor: pointer;
      button {
        margin: 0 6px;
        padding: 6px;
        font-size: 16px;
        font-weight: bold;
        background-color: rgb(63, 63, 100);
        color: #fff;
      }
    }
  }
}
